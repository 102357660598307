// import { useEffect, useState, useContext } from 'react';

// import { get, post } from 'aws-amplify/api';

// export const getOrders = async (accessToken, accountId) => {
//   // const dispatch = useContext(GlobalDispatchContext);
//   try {
//     const restOperation = get({
//       apiName: 'pylo',
//       path: '/pylo/orders',
//       options: {
//         queryParams: {
//           access_token: accessToken.toString(),
//           account_id: accountId,
//         },
//       },
//     });
//     const { body } = await restOperation.response;
//     const response = await body.json();
//     if (response) {
//       const sorted = sortedOrders(response);

//       dispatch({
//         type: 'SET_ORDERS',
//         payload: sorted,
//       });
//     }
//   } catch (error) {
//     console.error({ error });
//   } finally {
//     dispatch({
//       type: 'SET_IS_ORDER_LOADING',
//       payload: false,
//     });
//   }
// };

export const sortedOrders = (responseData) => {
  const sortedOrders = [];
  const dates = new Set(responseData.map((o) => o.date_placed));
  const sortedDates = Array.from(dates).sort().reverse();
  sortedDates.forEach((d) => {
    responseData
      .filter((o) => o.date_placed === d)
      .forEach((o) => {
        sortedOrders.push(o);
      });
  });
  return sortedOrders;
};
