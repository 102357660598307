import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Layout from '../mui-components/Layout';
import { addIds } from '../utils/helpers';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { GridToolbar } from '@mui/x-data-grid';
import { Box, Link, Button } from '@mui/material';
import DetailPanelContent from '../mui-components/OrderDetailsPanel';
import { NoRowsGrid, NoResultsGrid } from '../mui-components/NoRowsGrid';
import { pageTitles } from '../data/pageTitles';
import Loading from '../mui-components/Loading';
import { useGlobalContext } from '../context/GlobalStore';
import moment from 'moment';
import ToggleDatabase from '../mui-components/ToggleDatabase';

export default function Orders({ getOrders }) {
  document.title = pageTitles.orders;

  const { state } = useGlobalContext();
  const [rows, setRows] = useState([]);
  const getDetailPanelContent = useCallback(
    ({ row }) => <DetailPanelContent row={row} />,
    []
  );
  const states = [...new Set(state?.orders?.map((item) => item.state))];
  const orderStatus = [
    ...new Set(state?.orders?.map((item) => item.order_status)),
  ];
  const postalCode = [
    ...new Set(state?.orders?.map((item) => item.postal_code)),
  ];
  const cities = [...new Set(state?.orders?.map((item) => item.city))];

  const getDetailPanelHeight = useCallback(() => 400, []);
  const navigate = useNavigate();
  const columns = [
    {
      field: 'order_number',
      headerName: 'Order Number',
      width: 280,
      renderCell: (param) => (
        <Link onClick={() => navigate('/orders/'.concat(param.value))}>
          {param.value}
        </Link>
      ),
    },
    {
      field: 'order_status',
      headerName: 'Order Status',
      width: 180,
      type: 'singleSelect',
      valueOptions: orderStatus,
    },
    {
      field: 'date_placed',
      headerName: 'Date Placed',
      width: 170,
      type: 'dateTime',
      valueGetter: (value) => {
        if (!value) {
          return value;
        }
        return new Date(value);
      },
      valueFormatter: (value) => {
        return moment(value).format('L, LT');
      },
    },
    { field: 'tracking_number', headerName: 'Tracking Number', width: 150 },
    {
      field: 'address1',
      headerName: 'Address',
      width: 100,
      valueGetter: (value) => {
        if (!value) {
          return value;
        }
        return value.slice(0, 19);
      },
    },
    {
      field: 'city',
      headerName: 'City',
      width: 100,
      type: 'singleSelect',
      valueOptions: cities,
    },
    {
      field: 'state',
      headerName: 'State',
      width: 120,
      type: 'singleSelect',
      valueOptions: states,
    },
    {
      field: 'postal_code',
      headerName: 'Postal Code',
      width: 100,
      type: 'singleSelect',
      valueOptions: postalCode,
    },
  ];

  useEffect(() => {
    if (state.orders?.length > 0) {
      let copy = [...state.orders];
      const idAdded = addIds(
        copy.sort((a, b) => new Date(b.date_placed) - new Date(a.date_placed))
      );
      setRows(idAdded);
    } else {
      setRows([]);
    }
  }, [state.orders]);

  return (
    <Layout title="Orders">
      <Box
        justifyContent="space-between"
        display="flex"
        alignItems="center"
        sx={{ width: '100%', textAlign: 'right' }}
        mb={2}
      >
        <ToggleDatabase fetchData={getOrders} />

        {state.account.pylo_accounts.some(
          (el) => el.name === state.apiKey.name
        ) && (
          <Button variant="contained" onClick={() => navigate('/placeOrder')}>
            Place Order
          </Button>
        )}
      </Box>
      <Box sx={{ height: '100%' }}>
        {!state.isOrderLoading ? (
          <Box>
            <DataGridPro
              rows={rows}
              columns={columns}
              getDetailPanelHeight={getDetailPanelHeight}
              getDetailPanelContent={getDetailPanelContent}
              slots={{
                toolbar: GridToolbar,
                noRowsOverlay: NoRowsGrid,
                noResultsOverlay: NoResultsGrid,
              }}
              pagination={true}
              sx={{
                '& .MuiDataGridPro-overlay': { minHeight: 300 },
                '& .MuiDataGrid-overlayWrapper': { minHeight: 300 },
              }}
            />
          </Box>
        ) : (
          <Loading />
        )}
      </Box>
    </Layout>
  );
}
