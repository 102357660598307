const CodeBlock = (props) => {
  const { text, minWidth } = props;
  return (
    <code
      style={{
        background: 'black',
        border: '1px solid gray',
        padding: '0px 6px',
        color: 'lightgray',
        borderRadius: '5px',
        minWidth: minWidth || '100',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      {text}
    </code>
  );
};

export default CodeBlock;
