import { Box, Typography } from '@mui/material';
import SideMenu from './SideMenu';
import Loading from './LoadingBackdrop';
import ErrorBoundary from './ErrorBoundary';
import ErrorGenericFallback from './ErrorGenericFallback';

const Layout = (props) => {
  const { title, maxWidth, isSideMenu } = props;
  const styles = {
    width: 'calc(100vw - 250px)',
    textAlign: 'left',
    height: 'calc(100vh - 106px)',
    marginRight: 'auto',
    marginLeft: 'auto',
  };
  if (maxWidth) styles.maxWidth = maxWidth;

  return (
    <Box display="flex">
      <Loading />
      {isSideMenu !== false && <SideMenu />}
      
      <Box sx={styles} p={4} mt={8}>
        <Typography variant="h1" mb={2} color="text.primary">
          {title}
        </Typography>
        <ErrorBoundary fallbackComponent={ErrorGenericFallback}>
          {props.children}
        </ErrorBoundary>
      </Box>
    </Box>
  );
};

export default Layout;
