import React, { useContext } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import Logo from '../pylo.png';
import { useGlobalContext } from '../context/GlobalStore';
import { Box, Typography } from '@mui/material';

const Header = (props) => {
  const { signOut } = props;
  const { state } = useGlobalContext();
  return (
    <AppBar
      position="fixed"
      sx={{
        background: '#fff',
        color: 'primary.main',
        zIndex: 10,
        '& .MuiPaper-root': { zIndex: 10 },
      }}
      elevation={1}
    >
      <Toolbar sx={{ justifyContent: 'space-between' }}>
        {/* <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton> */}
        <img src={Logo} alt="Prevounce logo" style={{ height: 50 }} />
        <Box display="flex" alignItems="center" gap={2}>
          <Box display="flex">
            <Typography variant="body1" mr={1}>
              Hello,{' '}
            </Typography>
            <Typography variant="body1" sx={{ fontWeight: 600 }}>
              {state?.account?.authenticated_user?.email}
            </Typography>
          </Box>

          <Button color="primary" onClick={signOut} variant="outlined">
            Log out
          </Button>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
