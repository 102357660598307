import { useState, useEffect, memo } from 'react';
import { post } from 'aws-amplify/api';
import Layout from '../mui-components/Layout';
import Button from '@mui/material/Button';
import {
  Box,
  Table,
  Paper,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
  Typography,
  TableContainer,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { pageTitles } from '../data/pageTitles';
import { useGlobalContext } from '../context/GlobalStore';
import UserTableHead from '../mui-components/UserTableHead';
import UserRoleCell from '../mui-components/Account/UserRoleCell';
import ActionsCell from '../mui-components/Account/ActionsCell';
import AppAccessCell from '../mui-components/Account/AppAccessCell';
import CreateUserModal from '../mui-components/Account/CreateUserModal';
import AlertModal from '../mui-components/Account/AlertModal';
import ViewUserModal from '../mui-components/Account/ViewUserModal';
// import EditUserModal from '../mui-components/Account/EditUserModal';

const Account = ({ getAccount }) => {
  const { state } = useGlobalContext();
  const [openCreateUser, setOpenCreateUser] = useState(false);
  document.title = pageTitles.account;
  const [openAlert, setOpenAlert] = useState(false);
  const [openViewUser, setOpenViewUser] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [newUser, setNewUser] = useState({
    name: '',
    email: '',
    role: [],
    access: [],
  });
  const [accountUsers, setAccountUsers] = useState([]);
  const [deleteUserIndex, setDeleteUserIndex] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [currentAccountIndex, setCurrentAccountIndex] = useState(0);

  async function createUser(e) {
    e.preventDefault();
    setIsLoading(true);
    let policyDocument = {};
    if (newUser.role[0] === 'Admin') {
      const keys = Object.keys(state.policies);
      keys.forEach((key) => {
        policyDocument = Object.assign(policyDocument, state.policies[key]);
      });
    } else {
      newUser.access.forEach((item) => {
        policyDocument = Object.assign(policyDocument, state.policies[item]);
      });
      // dev or prod doc
    }
    // setAccountUsers(accountUsersCopy);
    const restOperation = post({
      apiName: 'pylo',
      path: '/pylo/user',
      options: {
        body: {
          action: 'create',
          access_token: state.token.toString(),
          name: newUser.name,
          email: newUser.email,
          policy_document: policyDocument,
        },
      },
    });
    const { body } = await restOperation.response;
    const response = await body.json();
    console.log('res from create user', { response });
    if (response) {
      getAccount(state.token.toString()).then((res) => {
        // close modal and loading off
        setIsLoading(false);
        toggleCreateUserModal();
        setNewUser({
          name: '',
          email: '',
          role: [],
          access: [],
        });
      });
    }
    // return val e.g. "818c6c2e-c993-4cbf-8a85-1d59c2fe1457"

    // if (accountUsers[i].api_key === 'new') {
    //   const accountUsersCopy = [...accountUsers];
    //   const policyDocument = {};
    //   policyDocument[response] =
    //     policyTemplates['dev']['5CtBo3JQOj2xAVfsi57om6LNjWgvSqvf3V5EXkVE'];
    //   accountUsersCopy[i].policy_document = policyDocument;
    //   setAccountUsers(accountUsersCopy);
    // }
    // const copy = { ...state.account };
    // copy.account_users = state.account.account_users.concat([accountUsers[i]]);
    // dispatch({
    //   type: 'SET_ACCOUNT',
    //   payload: copy,
    // });
  }

  async function deleteUser(i) {
    setIsLoading(true);
    const restOperation = post({
      apiName: 'pylo',
      path: '/pylo/user',
      options: {
        body: {
          action: 'delete',
          access_token: state.token.toString(),
          email: accountUsers[i].email,
        },
      },
    });
    const { body } = await restOperation.response;
    const response = await body.json();
    console.log('from deleteuser', { response });
    if (response) {
      getAccount(state.token.toString()).then((res) => {
        // close modal and loading off
        setIsLoading(false);
        setOpenAlert(false);
      });
    }
    // const copyAccountUsers = [...accountUsers];
    // copyAccountUsers.splice(i, 1);
    // setAccountUsers(copyAccountUsers);
    // const copy = { ...state.account };
    // copy.account_users.splice(i, 1);
    // setAccount(copy);
    // setOpenAlert(false);
  }

  const handleCreateUser = (e) => {
    createUser(e);
  };
  const toggleCreateUserModal = () => {
    setOpenCreateUser(!openCreateUser);
  };
  const viewUser = (i) => {
    setCurrentAccountIndex(i);
    setOpenViewUser(true);
  };
  const editUser = (i) => {
    setOpenEditModal(!openEditModal);
    setCurrentAccountIndex(i);
  };
  const alertDeleteUser = (i) => {
    setOpenAlert(!openAlert);
    setDeleteUserIndex(i);
  };
  const deleteUserByIndex = () => {
    deleteUser(deleteUserIndex);
  };
  const handleCloseAlert = () => {
    setOpenAlert(!openAlert);
    setDeleteUserIndex(null);
  };
  useEffect(() => {
    // update accountuser
    if (state?.account?.account_users?.length > 0) {
      const copy = [...state.account.account_users];
      setAccountUsers(copy);
    }
  }, [state.account]);

  return (
    <Layout title="Users">
      {openCreateUser && (
        <CreateUserModal
          open={true}
          handleClose={toggleCreateUserModal}
          handleCreateUser={handleCreateUser}
          newUser={newUser}
          setNewUser={setNewUser}
          isLoading={isLoading}
        />
      )}
      {openAlert && (
        <AlertModal
          open={true}
          handleClose={handleCloseAlert}
          deleteUser={deleteUserByIndex}
          userToDelete={accountUsers[deleteUserIndex]}
          isLoading={isLoading}
        />
      )}
      {/* {openViewUser && accountUsers.length > 0 && (
        <ViewUserModal
          open={true}
          handleClose={() => setOpenViewUser(!openViewUser)}
          account={accountUsers[currentAccountIndex]}
        />
      )} */}
      {/* {openEditModal && (
        <EditUserModal
          open={true}
          handleClose={() => setOpenEditModal(!openEditModal)}
          account={accountUsers[currentAccountIndex]}
        />
      )} */}
      <Box>
        <Box display="flex">
          <Typography variant="body1" color="GrayText" mr={2}>
            Customer Name:
          </Typography>
          <Typography variant="body1">
            {!!state?.account?.pylo_accounts?.length &&
              state.account.pylo_accounts[0].customer_name}
          </Typography>
        </Box>

        <Typography variant="body1">List of Users:</Typography>
        <TableContainer component={Paper}>
          <Table>
            <UserTableHead
              list={['Name', 'Email', 'User Role', 'App Access', 'Actions']}
            />
            <TableBody>
              {!!accountUsers?.length &&
                accountUsers.map((user, i) => {
                  const keys = Object.keys(user.policy_document);
                  const allRoles = keys.map((key) => {
                    return user.policy_document[key].map((doc) =>
                      doc.includes('admin:CreateUser') ? 'admin' : 'developer'
                    );
                  });
                  const roles = Array.from(new Set(allRoles.flat()));
                  const role = roles.includes('admin') ? 'admin' : 'developer';
                  // role was multiple before but not single. in case, left arr for future flexibilities
                  const appAccess = keys.map((key) => {
                    const acc = state.account.pylo_accounts.find((a) => a.api_key === key);
                    return (acc === undefined) ? null : acc.name;
                  }).filter((e) => e !== null);
                  return (
                    <TableRow key={user.email}>
                      <TableCell>{user['user_name']}</TableCell>
                      <TableCell>{user.email}</TableCell>
                      <UserRoleCell roles={[role]} />
                      <AppAccessCell accesses={appAccess} />

                      <ActionsCell
                        user={user}
                        deleteUser={() => alertDeleteUser(i)}
                        viewUser={() => viewUser(i)}
                        editUser={() => editUser(i)}
                      />
                      <TableCell>
                        {!!state?.account &&
                          i >= state.account.account_users.length && (
                            <IconButton
                              onClick={() => createUser(i)}
                              aria-label={`create this user`}
                            >
                              <AddIcon />
                            </IconButton>
                          )}
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>

        {state.account?.account_users?.length > 1 && (
          <Box mt={3}>
            <Button
              onClick={toggleCreateUserModal}
              variant="contained"
              sx={{ float: 'right' }}
            >
              Create User
            </Button>
          </Box>
        )}
      </Box>
    </Layout>
  );
};

export default memo(Account);
