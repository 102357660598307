import { useCallback } from 'react';
import { post } from 'aws-amplify/api';
import { useGlobalContext } from '../context/GlobalStore';

const useFetchDevices = () => {
  const { setIsDeviceLoading, setDevices } = useGlobalContext();
  const fetchData = useCallback(
    async (accessToken, accountId) => {
      console.log('useFetchDevices', { accountId });
      try {
        const restOperation = post({
          apiName: 'pylo',
          path: '/pylo/devices',
          options: {
            body: {
              access_token: accessToken.toString(),
              account_id: accountId,
            },
          },
        });
        const { body } = await restOperation.response;
        const response = await body.json();
        if (response) {
          setDevices(response);
        }
      } catch (error) {
        console.log('fetch devices', { error });
      } finally {
        setIsDeviceLoading(false);
      }
    },
    [setDevices, setIsDeviceLoading]
  );

  return fetchData;
};

export default useFetchDevices;
