import { post } from 'aws-amplify/api';
import React, { useState, useRef } from 'react';
import Chart from 'chart.js/auto';
import { CategoryScale } from 'chart.js';
import { Line } from 'react-chartjs-2';
import CodeBlock from '../mui-components/CodeBlock';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Layout from '../mui-components/Layout';
import { pageTitles } from '../data/pageTitles';

import { Box, Button, IconButton, MenuItem, Select } from '@mui/material';
import { useGlobalContext } from '../context/GlobalStore';
Chart.register(CategoryScale);

export default function ApiKeys() {
  document.title = pageTitles.apiKeys;
  const { state, setAccount } = useGlobalContext();
  const [apiKey, setApiKey] = useState(
    Object.keys(state.account.usage_data)[0]
  );
  const [isShowKey, setIsShowKey] = useState(false);
  const placeholder = '*****************************';

  const [isCopied, setIsCopied] = useState(false);
  // const keyRef = useRef(null);
  // const placeholder = '************';
  const [chartData, setChartData] = useState({
    labels: state.account.usage_data[apiKey].map((n) => '2024-01-01'),
    // import { Button, Box, Typography, Stack, IconButton } from '@mui/material';
    // import { styles } from '../mui-components/ApiKeys/styles';
    // import ContentCopyIcon from '@mui/icons-material/ContentCopy';
    // import { SuccessAlert } from '../mui-components/ApiKeys/CopySuccess';

    // Chart.register(CategoryScale);

    // export default function ApiKeys({ account }) {
    //   // console.log('account.usage_data', account.usage_data);
    //   document.title = pageTitles.apiKeys;
    //   const chartData = {
    //     labels: account.usage_data.map((n) => '2024-01-01'),
    datasets: [
      {
        label: 'Used',
        data: state.account.usage_data[apiKey].map(([used, remaining]) => used),
      },
      {
        label: 'Remaining',
        data: state.account.usage_data[apiKey].map(
          ([used, remaining]) => remaining
        ),
      },
    ],
  });

  const handleChange = (api_key) => {
    setApiKey(api_key);
    setChartData({
      labels: state.account.usage_data[api_key].map((n) => '2024-01-01'),
      datasets: [
        {
          label: 'Used',
          data: state.account.usage_data[api_key].map(
            ([used, remaining]) => used
          ),
        },
        {
          label: 'Remaining',
          data: state.account.usage_data[api_key].map(
            ([used, remaining]) => remaining
          ),
        },
      ],
    });
  };

  // const toggleShowKey = () => {
  //   if (isShowKey) {
  //     setIsCopied(false);
  //   }
  //   setIsShowKey(!isShowKey);
  // };
  // const copyKey = () => {
  //   // keyRef.current.select();
  //   navigator.clipboard.writeText(state.account.api_key);
  //   setIsCopied(true);
  // };

  async function rotateKey(api_key) {
    try {
      const accountId = state.account.pylo_accounts.find(
        (a) => a.key_id === api_key
      )['id'];
      const restOperation = post({
        apiName: 'pylo',
        path: '/pylo/apiKeys',
        options: {
          body: {
            access_token: state.token.toString(),
            account_id: accountId,
            action: 'rotate',
          },
        },
      });

      const { body } = await restOperation.response;
      const response = await body.json();
      const copy = { ...state.account };
      const a = copy.pylo_accounts.find((a) => a.key_id === api_key);
      const usageDataCopy = copy.usage_data;
      delete usageDataCopy[a.key_id];
      a['key_id'] = response['key_id'];
      a['api_key'] = response['api_key'];
      usageDataCopy[response['key_id']] = [];
      copy['usage_data'] = usageDataCopy;
      setAccount(copy);
      setApiKey(a['key_id']);
      console.log(response);
      console.log(copy);
    } catch (e) {
      console.log('POST call failed: ', JSON.parse(e.response.body));
    }
  }

  const toggleVisiblity = () => {
    setIsShowKey(!isShowKey);
  };

  return (
    <Layout title="">
      <div className="col-75">
        <Box display="flex" alignItems="center" gap={3}>
          <div>
            App:&nbsp;&nbsp;
            <Select
              size="small"
              value={apiKey}
              onChange={(event) => handleChange(event.target.value)}
            >
              {Object.entries(state.account.usage_data).map(([key, val]) => {
                const selectedAccount = state.account.pylo_accounts.find(
                  (account) => account.key_id === key
                );
                return (
                  <MenuItem value={key} key={key}>
                    {selectedAccount.name}
                    {/* <code
                      style={{
                        background: 'black',
                        border: '1px solid gray',
                        padding: '7px 8px',
                        color: 'lightgray',
                        borderRadius: '5px',
                      }}
                    >
                      {key}
                    </code> */}
                  </MenuItem>
                );
              })}
            </Select>
          </div>
        </Box><br />

        <Box display="flex">
          <CodeBlock
            minWidth={400}
            text={isShowKey ? state.account.pylo_accounts.find((a) => a.key_id === apiKey).api_key : placeholder}
          />
          <IconButton onClick={() => toggleVisiblity()}>
            {isShowKey ? <VisibilityOffIcon /> : <RemoveRedEyeIcon />}
          </IconButton>
        </Box>

        <Line
          data={chartData}
          style={{ maxWidth: '50%', maxHeight: '500px' }}
        />
      </div>
      {/* <Layout title="API Key">
      <Box>
        <p>
          Api Key:&nbsp;&nbsp;
          <Select
            value={apiKey}
            onChange={(event) => handleChange(event.target.value)}
          >
            {Object.entries(account.usage_data).map(([key, val]) => (
              <MenuItem value={key} key={key}>
                <code
                  style={{
                    background: 'black',
                    border: '1px solid gray',
                    padding: '7px 8px',
                    color: 'lightgray',
                    borderRadius: '5px',
                  }}
                >
                  {key}
                </code>
              </MenuItem>
            ))}
          </Select>
        </p>
        <Stack direction="row" alignItems="center" spacing={1}>
          <Typography variant="body1">API Key:</Typography>
          <Box>
            <code style={styles.code} ref={keyRef}>
              {isShowKey ? account.api_key : placeholder}
            </code>
            {isShowKey && (
              <IconButton
                variant="outlined"
                color="info"
                sx={{ width: 40, height: 40 }}
                onClick={copyKey}
              >
                <ContentCopyIcon color="info" />
              </IconButton>
            )}
          </Box>
          <Button
            onClick={getNewApiKey}
            variant="contained"
            size="small"
            sx={styles.btn}
            color="error"
          >
            Refresh
          </Button>
          <Button
            onClick={toggleShowKey}
            variant="contained"
            size="small"
            sx={styles.btn}
          >
            {isShowKey ? 'Hide' : 'Show'}
          </Button>
        </Stack>
        <Box sx={{ height: 76 }}>{isCopied && <SuccessAlert />}</Box>
        
        <Line data={chartData} style={styles.chart} />
      </Box> */}
    </Layout>
  );
}
