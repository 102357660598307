import React from 'react';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  DialogActions,
  TextField,
  Typography,
  Box,
} from '@mui/material';
import { policies, selectItems } from '../../data/userSettings';
import PolicyList from './PolicyList';
import ApiKeyBlock from './ApiKeyBlock';
import { useGlobalContext } from '../../context/GlobalStore';
import { styles } from './styles';
import MultiSelectChip from './MultiSelectChip';
import Loading from '../../mui-components/Loading';

const CreateUserModal = (props) => {
  const {
    open,
    handleClose,
    handleCreateUser,
    newUser,
    setNewUser,
    isLoading,
  } = props;
  const { state } = useGlobalContext();
  const accessList = state.account.pylo_accounts.map((account) => account.name);
  selectItems[1].list = accessList;
  const inputItems = [
    { label: 'Name', name: 'name', type: 'text' },
    { label: 'Email', name: 'email', type: 'email' },
  ];

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'access') {
      if (newUser.role[0] === 'Admin') {
        return;
      }
      let copy = [...newUser[name]];
      if (newUser[name].includes(value)) {
        copy = copy.filter((item) => item !== value);
      } else {
        copy.push(value);
      }
      setNewUser((prev) => ({
        ...prev,
        [name]: copy,
      }));
    } else if (name === 'role') {
      if (value === 'Admin') {
        // set access both prod and dev
        setNewUser((prev) => ({
          ...prev,
          [name]: [value],
          access: accessList,
        }));
      } else {
        setNewUser((prev) => ({
          ...prev,
          [name]: [value],
        }));
      }
    } else {
      setNewUser((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="lg"
      onSubmit={handleCreateUser}
      component="form"
    >
      {isLoading ? (
        <DialogContent sx={styles.loadingBox}>
          <Loading />
        </DialogContent>
      ) : (
        <>
          <DialogTitle id="alert-dialog-title">{'Create User'}</DialogTitle>
          <DialogContent sx={styles.contentBox}>
            {inputItems.map((item) => {
              return (
                <Box
                  display="flex"
                  alignItems="center"
                  key={`input-${item.name}`}
                >
                  <Typography sx={styles.label} variant="body1">
                    {item.label}:
                  </Typography>
                  <TextField
                    name={item.name}
                    size="small"
                    sx={styles.input}
                    type={item.type}
                    onChange={handleChange}
                    required
                  />
                </Box>
              );
            })}
            {selectItems.map((item) => {
              return (
                <Box key={`select-${item.name}`}>
                  <Box display="flex" alignItems="center">
                    <Typography variant="body1" sx={styles.label}>
                      {item.label}:
                    </Typography>
                    <MultiSelectChip
                      user={newUser}
                      handleChange={handleChange}
                      item={item}
                    />
                  </Box>
                  {newUser?.role?.length > 0 && item.name === 'role' && (
                    <Box sx={{ marginLeft: '110px' }} display="flex">
                      {newUser.role.includes('Admin') && (
                        <PolicyList list={policies.admin.list} />
                      )}
                      {newUser.role.length > 0 && (
                        <PolicyList list={policies.dev.list} />
                      )}
                    </Box>
                  )}
                </Box>
              );
            })}
            {newUser?.access?.length > 0 &&
              newUser.access.map((item, i) => {
                return (
                  <Box
                    display="flex"
                    alignItems="center"
                    key={`apikey-${item}`}
                  >
                    <Typography variant="body1" sx={styles.label}>
                      {` `}
                    </Typography>
                    <ApiKeyBlock
                      apikey={
                        state.account.pylo_accounts.find(
                          (ele) => ele.name === item
                        ).api_key
                      }
                      access={item}
                    />
                  </Box>
                );
              })}
          </DialogContent>
          <DialogActions sx={{ padding: 3 }}>
            <Button onClick={handleClose} variant="outlined">
              Cancel
            </Button>
            <Button variant="contained" type="submit" autoFocus>
              Create
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

export default CreateUserModal;
