import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Link from '@mui/material/Link';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { useNavigate } from 'react-router-dom';

import { addIds } from '../utils/helpers';

export default function DetailPanelContent({ row }) {
  const rows = addIds(row.devices);
  const navigate = useNavigate();
  const columns = [
    { field: 'sku', headerName: 'SKU' },
    { field: 'quantity', headerName: 'Quantity', width: 80 },
    {
      field: 'imeis',
      headerName: 'Imeis',
      renderCell: (param) => (
        <Link onClick={() => navigate('/devices/'.concat(param.value))}>
          {param.value}
        </Link>
        // <Typography>
        //   {param.value.map((item, i) =>
        //     i !== param.value.length - 1 ? item + ' |' : item
        //   )}
        // </Typography>
      ),
    },
  ];
  return (
    <Stack
      sx={{ py: 2, height: '100%', boxSizing: 'border-box' }}
      direction="column"
    >
      <Paper sx={{ flex: 1, mx: 'auto', width: '90%', p: 1 }}>
        <Stack direction="column" spacing={1} sx={{ height: 1 }}>
          <Typography variant="h5">{`Order # ${row.order_number}`}</Typography>
          <Grid container>
            <Grid item md={6}>
              <Typography variant="body2" color="textSecondary" mb={1}>
                Recipient information
              </Typography>
              <Typography variant="body1">{row.company_recipient}</Typography>
              <Typography variant="body1">
                {row.first_name_recipient} {row.last_name_recipient}
              </Typography>
              <Typography variant="body1">{row.email_recipient}</Typography>
            </Grid>
            <Grid item md={6}>
              <Typography
                variant="body2"
                align="right"
                color="textSecondary"
                mb={1}
              >
                Shipping address
              </Typography>
              <Typography variant="body1" align="right">
                {row.address1}
              </Typography>
              <Typography
                variant="body1"
                align="right"
              >{`${row.city}, ${row.state} ${row.postal_code}`}</Typography>
            </Grid>
          </Grid>
          <Box>
            <DataGridPro
              density="compact"
              columns={columns}
              rows={rows}
              sx={{ flex: 1 }}
              hideFooter
            />
          </Box>
        </Stack>
      </Paper>
    </Stack>
  );
}
