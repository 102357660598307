import { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { post } from 'aws-amplify/api';
import { GlobalDispatchContext } from '../context/GlobalStore';
import {
  TextField,
  Stack,
  Typography,
  Select,
  Button,
  Grid,
  MenuItem,
  Box,
} from '@mui/material';
import Layout from '../mui-components/Layout';
import {
  createSubmitForm,
  validateEmail,
  validatePhoneNum,
} from '../utils/helpers';
import FormDevices from '../mui-components/FormDevices';
import { formItems, shippingOptions } from '../data/orderForm';
import { getLocation } from '../utils/addressAutocomplete';
import FormAddress from '../mui-components/FormAddress';
import ErrorGenericFallback from '../mui-components/ErrorGenericFallback';
import { pageTitles } from '../data/pageTitles';
import { orderErrMsg } from '../data/orderForm';
import { useGlobalContext } from '../context/GlobalStore';
import ToggleDatabase from '../mui-components/ToggleDatabase';

export default function PlaceOrder() {
  document.title = pageTitles.placeOrder;
  const { state, setOrders } = useGlobalContext();
  const navigate = useNavigate();
  const [latLong, setLatLong] = useState(null);

  const initialSkuVal = '';
  const [devices, setDevices] = useState([
    {
      sku: initialSkuVal,
      quantity: 0,
    },
  ]);
  const [form, setForm] = useState({
    fname: '',
    lname: '',
    phone: '',
    email: '',
    company: '',
    address1: '',
    city: '',
    state: '',
    pcode: null,
    ccode: 'US',
    shipping: shippingOptions[0].list[0],
    signature: shippingOptions[1].list[0],
  });
  const dispatch = useContext(GlobalDispatchContext);
  const [errors, setErrors] = useState(Array(13).fill(false));
  const [prices, setPrices] = useState([]);
  const [sameErrs, setSameErrs] = useState([]);
  const [options, setOptions] = useState([]);
  const [orderError, setOrderError] = useState(false);

  const onChange = (e, i) => {
    const copy = [...errors];
    switch (e.target.name) {
      case 'email':
        if (validateEmail(e.target.value)) {
          copy[i] = false;
          setErrors(copy);
        } else {
          copy[i] = true;
          setErrors(copy);
        }
        break;
      case 'phone':
        if (validatePhoneNum(e.target.value)) {
          copy[i] = false;
          setErrors(copy);
        } else {
          copy[i] = true;
          setErrors(copy);
        }
        break;
      default:
        break;
    }
    setForm({ ...form, [e.target.name]: e.target.value });
  };
  const onChangeDevice = (e, i) => {
    const errCopy = [...sameErrs];
    const copy = [...devices];
    if (
      e.target.name === 'sku' &&
      devices.some((device) => device.sku === e.target.value)
    ) {
      errCopy[i] = true;
      setSameErrs(errCopy);
      copy[i][e.target.name] = '';
      setDevices(copy);
    } else {
      errCopy[i] = false;
      setSameErrs(errCopy);

      copy[i][e.target.name] = e.target.value;
      setDevices(copy);
    }
  };
  const handleSubmit = (event) => {
    event.preventDefault();

    dispatch({
      type: 'SET_IS_LOADING',
      payload: true,
    });

    const body = createSubmitForm(form, devices);
    placeOrder(body);
  };

  async function placeOrder(orderDetails) {
    try {
      const accountId = state.account.pylo_accounts.find(
        (a) => a.api_key === state.apiKey.key
      )['id'];
      const restOperation = post({
        apiName: 'pylo',
        path: '/pylo/placeOrder',
        options: {
          body: {
            access_token: state.token.toString(),
            order: orderDetails,
            account_id: accountId,
          },
        },
      });

      const { body } = await restOperation.response;
      const response = await body.json();
      setOrders([response].concat(state.orders));
      navigate('/placeOrder/' + response.order_number);
      dispatch({
        type: 'SET_IS_LOADING',
        payload: false,
      });
    } catch (e) {
      console.log('POST call failed: ', JSON.parse(e.response.body));
      setOrderError(true);
    }
  }
  const setAddress = (e) => {
    if (e.target.outerText) {
      const arr = e.target.outerText.split(',');
      const len = arr.length;
      setForm({
        ...form,
        address1: arr.slice(0, len - 4).join(),
        city: arr
          .slice(len - 4, len - 3)
          .join()
          .trim(),
        state: arr
          .slice(len - 3, len - 2)
          .join()
          .trim(),
        pcode: arr
          .slice(len - 2, len - 1)
          .join()
          .trim(),
      });
    }
  };

  const getOptions = async () => {
    if (form?.address1) {
      const res = await getLocation(form?.address1, latLong);
      console.log('paylod', res?.Results);
      if (res?.Results.length > 0) {
        setOptions(res.Results);
      } else {
        setOptions([]);
      }
    }
  };

  useEffect(() => {
    getOptions();
  }, [form]);

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        setLatLong([position.coords.longitude, position.coords.latitude]);
      });
    }
  }, []);

  return (
    <Layout title="Place Order" maxWidth={1200}>
      {orderError ? (
        <ErrorGenericFallback
          title={orderErrMsg.title}
          msg={orderErrMsg.msg}
          link={orderErrMsg.link}
          linkText={orderErrMsg.linkText}
        />
      ) : (
        <Box>
          <ToggleDatabase />
          <Stack
            gap={3}
            sx={{ width: '100%' }}
            component="form"
            onSubmit={handleSubmit}
            pb={3}
          >
            <Grid container spacing={2}>
              {formItems.map((item, i) => {
                return (
                  <Grid
                    item
                    md={item.name !== 'company' ? 6 : 12}
                    key={`${item.name}-${i}`}
                  >
                    <Typography variant="overline" color="text.primary">
                      {item.label}:{' '}
                    </Typography>
                    <TextField
                      required
                      name={item.name}
                      placeholder={
                        item.name === 'phone' ? 'e.g. 111-111-1111' : ''
                      }
                      fullWidth
                      size="small"
                      onChange={(e) => onChange(e, i)}
                      error={errors[i]}
                      helperText={errors[i] ? 'Incorrect entry.' : ''}
                    />
                  </Grid>
                );
              })}
            </Grid>
            <FormAddress
              onChange={onChange}
              options={options}
              errors={errors}
              form={form}
              setAddress={setAddress}
            />
            <Grid container spacing={2}>
              {shippingOptions.map((opt, i) => {
                return (
                  <Grid item sm={6} key={opt.label}>
                    <Typography variant="overline" color="text.primary">
                      {opt.label}:{' '}
                    </Typography>
                    <Select
                      fullWidth
                      size="small"
                      defaultValue={opt.list[0]}
                      onChange={(e) => onChange(e, i + 11)}
                    >
                      {opt.list.map((item, i) => {
                        return (
                          <MenuItem value={item} key={`${opt.label}-${item}`}>
                            {item}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </Grid>
                );
              })}
            </Grid>

            <FormDevices
              devices={devices}
              onChangeDevice={onChangeDevice}
              setDevices={setDevices}
              products={state.account.pylo_accounts.find((a) => a.api_key === state.apiKey.key).product_offerings}
              prices={prices}
              sameErrs={sameErrs}
            />
            <Button variant="contained" type="submit" fullWidth>
              Submit Order
            </Button>
          </Stack>
        </Box>
      )}
    </Layout>
  );
}
