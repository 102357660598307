import { TableCell, Chip } from '@mui/material';
import { purple, green } from '@mui/material/colors';

const AppAccessCell = (props) => {
  const { accesses } = props;

  return (
    <TableCell>
      {accesses.map((access) => {
        const isProd = access === 'Production';
        return (
          <Chip
            label={access}
            key={`app-access-${access}`}
            sx={{
              marginRight: 1,
              borderRadius: 6,
              backgroundColor: isProd ? green[100] : purple[100],
            }}
          />
        );
      })}
    </TableCell>
  );
};

export default AppAccessCell;
