import { Box, Typography } from '@mui/material';
import CodeBlock from '../CodeBlock';
import { useGlobalContext } from '../../context/GlobalStore';

const ApiKeyBlock = (props) => {
  const { access } = props;
  const { state } = useGlobalContext();
  const apiKey = state.account.pylo_accounts.find(
    (ele) => ele.name === access
  ).api_key;
  const apiKeyId = state.account.pylo_accounts.find(
    (ele) => ele.name === access
  ).key_id;
  return (
    <Box display="flex" alignItems="center" gap={1}>
      <Typography variant="body1" sx={{ width: 124 }}>
        {`API key (${access}):`}
      </Typography>
      <CodeBlock text={apiKey} />
      <Typography variant="body1" sx={{ width: 124 }}>
        {`ID: `}
        <strong>{apiKeyId}</strong>
      </Typography>
    </Box>
  );
};

export default ApiKeyBlock;
