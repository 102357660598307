import * as React from 'react';
import {
  Drawer,
  Divider,
  Typography,
  ListItemButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
} from '@mui/material';
import DevicesOtherIcon from '@mui/icons-material/DevicesOther';
import KeyIcon from '@mui/icons-material/Key';
import WebhookIcon from '@mui/icons-material/Webhook';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
// import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout';
import ListAltIcon from '@mui/icons-material/ListAlt';

const drawerWidth = 250;

const SideMenu = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { pathname } = location;
  const menuItemsDevice = ['Devices', 'Orders'];
  const menuItemsAccount = ['API Keys', 'Webhooks', 'Users'];
  const pathNamesDevice = ['/', '/orders'];
  const pathNamesAccount = ['/apiKeys', '/webhooks', '/account'];
  const iconsDevice = [
    <DevicesOtherIcon />,
    <ListAltIcon />,
    // <ShoppingCartCheckoutIcon />,
  ];
  const iconsAccount = [<KeyIcon />, <WebhookIcon />, <AccountCircleIcon />];
  return (
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: 'border-box',
          zIndex: 1,
          background: '#EBF2F7',
        },
      }}
      variant="permanent"
      anchor="left"
    >
      <Toolbar />
      <Typography variant="body1" sx={style.subtitle}>
        Menu
      </Typography>
      <List>
        {menuItemsDevice.map((text, i) => (
          <ListItem key={text} disablePadding>
            <ListItemButton
              selected={pathname === pathNamesDevice[i]}
              onClick={() => navigate(pathNamesDevice[i])}
            >
              <ListItemIcon>{iconsDevice[i]}</ListItemIcon>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Divider />
      <Typography variant="body1" sx={style.subtitle}>
        Account Management
      </Typography>
      <List>
        {menuItemsAccount.map((text, i) => (
          <ListItem key={text} disablePadding>
            <ListItemButton
              selected={pathname === pathNamesAccount[i]}
              onClick={() => navigate(pathNamesAccount[i])}
            >
              <ListItemIcon>{iconsAccount[i]}</ListItemIcon>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Drawer>
  );
};

export default SideMenu;

const style = {
  subtitle: { textAlign: 'left', paddingLeft: 2, marginTop: 2, color: 'gray' },
};
