import { useEffect, useState } from 'react';
import { post } from 'aws-amplify/api';
import { pageTitles } from '../data/pageTitles';
import Layout from '../mui-components/Layout';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import CreateFirstWebhook from '../mui-components/Webhooks/CreateFirstWebhook';
import Webhook from '../mui-components/Webhooks/Webhook';
import { Box, Stack } from '@mui/material';
import { useGlobalContext } from '../context/GlobalStore';
import ToggleDatabase from '../mui-components/ToggleDatabase';

export default function Webhooks() {
  document.title = pageTitles.webhooks;

  const { state, setAccount } = useGlobalContext();
  const [apiKey, setApiKey] = useState(
    !!state?.account ? state?.account?.pylo_accounts[0]?.api_key : ''
  );
  const [webhook, setWebhook] = useState();
  const [url, setUrl] = useState('');

  useEffect(() => {
    // set webhook url
    if (state.account) {
      setWebhook(state.account?.webhooks[0]);
    }
  }, []);
  // const handleChange = (api_key) => {
  //   setApiKey(api_key);
  //   const customer = state?.account?.pylo_accounts?.find(
  //     (a) => a.api_key === api_key
  //   )['id'];
  //   const new_webhook = state.account?.webhooks?.find(
  //     (w) => w.customer_id === customer
  //   );
  //   setWebhook(new_webhook);
  // };

  async function rotateUrl() {
    try {
      const accountId = state?.account?.pylo_accounts?.find(
        (a) => a.api_key === apiKey
      )['id'];
      const restOperation = post({
        apiName: 'pylo',
        path: '/pylo/webhooks',
        options: {
          body: {
            access_token: state.token.toString(),
            account_id: accountId,
            new_url: url,
          },
        },
      });

      const { body } = await restOperation.response;
      const response = await body.json();
      const copy = { ...state.account };
      const wh = copy.webhooks.find((w) => w.customer_id === accountId);
      wh['url'] = url;
      setAccount(copy);
      console.log(response);
    } catch (e) {
      console.log('POST call failed: ', JSON.parse(e.response.body));
    }
  }

  // return (
  //   <Layout title="Webhooks">
  //     {account?.webhook_url ? (
  //       <Webhook url={account?.webhook_url} />
  //     ) : (
  //       <CreateFirstWebhook />
  //     )}

  return (
    <Layout title="Webhook">
      <Stack gap={3}>
        <ToggleDatabase />
        {/* <p>
          Api Key:&nbsp;&nbsp;
          <Select
            value={apiKey}
            onChange={(event) => handleChange(event.target.value)}
          >
            {!!state?.account?.pylo_accounts?.length &&
              Object.entries(state.account.pylo_accounts).map(([key, val]) => (
                <MenuItem value={val['api_key']}>
                  <code
                    style={{
                      background: 'black',
                      border: '1px solid gray',
                      padding: '7px 8px',
                      color: 'lightgray',
                      borderRadius: '5px',
                    }}
                  >
                    {val['api_key']}
                  </code>
                </MenuItem>
              ))}
          </Select>
        </p> */}
        <Box alignItems="center" display="flex">
          Webhook URL:&nbsp;&nbsp;
          <code
            style={{
              background: 'black',
              border: '1px solid gray',
              padding: '7px 8px',
              color: 'lightgray',
              borderRadius: '5px',
            }}
          >
            {state.account.webhooks.find((w) => w.customer_id === state.account.pylo_accounts.find((a) => a.api_key === state.apiKey.key)['id']).url}
          </code>
        </Box>
        {/* {state?.account?.webhook_url ? (
          <Webhook url={state.account.webhook_url} />
        ) : (
          <CreateFirstWebhook />
        )} */}
      </Stack>
    </Layout>
  );
}
