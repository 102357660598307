import { useNavigate } from 'react-router-dom';
import Layout from '../mui-components/Layout';
import { Link, Box, Stack } from '@mui/material';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { addIds } from '../utils/helpers';
import { GridToolbar } from '@mui/x-data-grid';
import { NoRowsGrid, NoResultsGrid } from '../mui-components/NoRowsGrid';
import { pageTitles } from '../data/pageTitles';
import Loading from '../mui-components/Loading';
import { useEffect, useState } from 'react';
import { useGlobalContext } from '../context/GlobalStore';
import moment from 'moment';
import ToggleDatabase from '../mui-components/ToggleDatabase';

export default function Devices({ getDevices }) {
  const { state } = useGlobalContext();
  document.title = pageTitles.devices;
  const models = [...new Set(state.devices.map((item) => item.device_model))];
  const billingStatus = [
    ...new Set(state.devices.map((item) => item.billing_status)),
  ];
  const deviceTypes = [
    ...new Set(state.devices.map((item) => item.device_type)),
  ];

  const [rows, setRows] = useState([]);
  const navigate = useNavigate();

  const columns = [
    { field: 'device_id', headerName: 'Device Id', width: 120 },
    {
      field: 'device_imei',
      headerName: 'Imei',
      width: 140,
      renderCell: (param) => (
        <Link
          onClick={() =>
            navigate('/devices/'.concat(param.value), {
              state: {
                account_id:
                  state.account?.pylo_accounts?.find(
                    (a) => a.api_key === state.apiKey.key
                  )['id'] || '',
              },
            })
          }
        >
          {param.value}
        </Link>
      ),
    },
    {
      field: 'device_type',
      headerName: 'Device Type',
      width: 120,
      type: 'singleSelect',
      valueOptions: deviceTypes,
    },
    {
      field: 'device_model',
      headerName: 'Device Model',
      width: 140,
      type: 'singleSelect',
      valueOptions: models,
    },
    {
      field: 'first_measurement_timestamp',
      headerName: 'First Reading',
      type: 'dateTime',
      width: 170,
      valueGetter: (value) => {
        if (!value) {
          return value;
        }
        return new Date(value);
      },
      valueFormatter: (value) => {
        return value ? moment(value).format('L, LT') : value;
      },
    },
    {
      field: 'last_measurement_timestamp',
      headerName: 'Last Reading',
      type: 'dateTime',
      width: 170,
      valueGetter: (value) => {
        if (!value) {
          return value;
        }
        return new Date(value);
      },
      valueFormatter: (value) => {
        return value ? moment(value).format('L, LT') : value;
      },
    },
    {
      field: 'billing_status',
      headerName: 'Billing Status',
      width: 100,
      type: 'singleSelect',
      valueOptions: billingStatus,
    },
    {
      field: 'order_number',
      headerName: 'Order Number',
      width: 120,
      renderCell: (param) => (
        <Link onClick={() => navigate('/orders/'.concat(param.value))}>
          {param.value}
        </Link>
      ),
    },
  ];
  useEffect(() => {
    if (state.devices?.length > 0) {
      const idAttached = addIds(state.devices);
      setRows(idAttached);
    } else {
      setRows([]);
    }
  }, [state.devices]);

  // async function handleChange(api_key) {
  //   setIsDeviceLoading(true);
  //   const new_account = account.pylo_accounts.find(
  //     (a) => a.api_key === api_key
  //   );
  //   console.log('new_account', new_account);
  //   setApiKey(api_key);
  //   getDevices(token, new_account['id']);
  //   setIsFirstTime(false);
  // }

  return (
    <Layout title="Devices">
      <Box sx={{ height: '100%' }}>
        {!state.isDeviceLoading ? (
          <Stack gap={2}>
            <ToggleDatabase fetchData={getDevices} />

            <DataGridPro
              rows={rows}
              columns={columns}
              slots={{
                toolbar: GridToolbar,
                noRowsOverlay: NoRowsGrid,
                noResultsOverlay: NoResultsGrid,
              }}
              pagination={true}
              sx={{
                '& .MuiDataGridPro-overlay': { minHeight: 300 },
                '& .MuiDataGrid-overlayWrapper': { minHeight: 300 },
              }}
            />
          </Stack>
        ) : (
          <Loading />
        )}
      </Box>
    </Layout>
  );
}
