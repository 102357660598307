import { Image, useTheme } from '@aws-amplify/ui-react';
import PyloLogo from '../pylo.png';
import { Box } from '@mui/material';

export default function LoginHeader() {
  const { tokens } = useTheme();

  return (
    <Box
      display="flex"
      justifyContent="center"
      sx={{ width: '100%', background: 'white' }}
    >
      <Image
        alt="Pylo Management Portal logo"
        src={PyloLogo}
        padding={tokens.space.medium}
      />
    </Box>
  );
}
