export const addIds = (data) => {
  for (let i = 0; i < data.length; i++) {
    data[i].id = i;
  }
  return data;
};

export const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export const validatePhoneNum = (num) => {
  return String(num).match(/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/);
};

export const createSubmitForm = (form, devices) => {
  return {
    recipient_first_name: form.fname,
    recipient_last_name: form.lname,
    recipient_phone: form.phone,
    recipient_email: form.email,
    recipient_company: form.company,
    address1: form.address1,
    address2: form.address2,
    city: form.city,
    state: form.state,
    postal_code: form.pcode,
    country_code: form.ccode,
    billing_info: {
      name: form.company,
      street1: form.address1,
      city: form.city,
      state: form.state,
      country: form.ccode,
      postal_code: form.pcode,
    },
    order_requested_shipping_service: form.shipping,
    require_signature: form.signature,
    devices: devices.map((device) => ({
      item_sku: device.sku,
      quantity: +device.quantity,
    })),
  };
};
